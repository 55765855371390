<template>
	<div class="modal">
		<div class="modal-container">
			<div class="modal-content tile">
				<div class="modal-header">
					<div class="modal-title">
						<h3>Change Location Manager</h3>
					</div>

					<router-link class="close" :to="{ name: 'LocationDetail' }">
						<fa icon="times" />
					</router-link>
				</div>
				<div class="modal-body">
					<div class="modal-messages" v-if="isLoading">
						<div class="error-text" v-if="error">{{ error }}</div>
						<page-loading />
					</div>
					<div
						class="employee-list"
						v-for="doc in filteredEmployees"
						:key="doc.id"
						v-else
					>
						<div class="employee">
							<div class="employee-details">
								<div class="circle-avatar">
									<img
										class="img-responsive"
										:src="doc.profile.profileImage"
									/>
								</div>
								<div class="name">
									{{ doc.profile.fullName }}
								</div>
							</div>
							<div class="add-employee">
								<div
									class="btn-icon"
									@click="changeManager(doc.id)"
								>
									<fa icon="user-plus" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import {
	projectFirestore,
	addArrayItem,
	removeArrayItem,
} from "../../firebase/config";
import useCookies from "../../composables/useCookies";
import getCollection from "../../composables/getCollection";
import { useRouter, useRoute } from "vue-router";
import { computed } from "@vue/runtime-core";
import PageLoading from "../../components/PageLoading.vue";

export default {
	components: { PageLoading },
	name: "AddLocationManager",
	setup() {
		const { getCookie } = useCookies();
		const route = useRoute();
		const companyId = getCookie("rm_cId");
		let locationId = route.query.locationId;
		let managerId = route.query.managerId;
		const error = ref("");
		const router = useRouter();
		const isLoading = ref(false);

		// Collections
		let db = projectFirestore;

		let companyRef = db.collection("companies").doc(companyId);

		// Get the employees collection
		let employeesRef = companyRef
			.collection("employees")
			.where("locations", "array-contains", locationId);
		const { documents } = getCollection(employeesRef);

		const availableManagers = ref(null);

		const filteredEmployees = computed(() => {
			availableManagers.value = documents.value;

			if (documents.value) {
				availableManagers.value = availableManagers.value.filter(
					(employee) => {
						return (
							employee.role == "Admin" ||
							employee.role == "Contributor"
						);
					}
				);
			}

			return availableManagers.value;
		});

		let locationsRef = companyRef.collection("locations");

		// Employee and location batch write
		let batch = db.batch();

		const changeManager = async (id) => {
			isLoading.value = true;

			// Remove the location from the current manager
			let managerRef = companyRef.collection("employees").doc(managerId);
			batch.update(managerRef, {
				manages: removeArrayItem(locationId),
			});

			// Add location to the selected employee
			let updateEmployeeRef = companyRef.collection("employees").doc(id);
			batch.update(updateEmployeeRef, {
				manages: addArrayItem(locationId),
			});

			// update the manager id on the location doc
			let updateLocationRef = locationsRef.doc(locationId);
			batch.update(updateLocationRef, {
				manager: id,
			});

			// Commit the batch
			batch
				.commit()
				.then(() => {
					isLoading.value = false;
					router.push({
						name: "LocationDetail",
						params: { id: locationId },
					});
				})
				.catch((err) => {
					console.log(err.message);
					error.value = "Could not add user";
					setTimeout(() => {
						isLoading.value = false;
						router.push({
							name: "LocationDetail",
							params: { id: locationId },
						});
					}, 2000);
				});
		};

		return {
			documents,
			filteredEmployees,
			changeManager,
			error,
			isLoading,
		};
	},
};
</script>

<style lang="scss" scoped>
.employee-list {
	background-color: white;
	padding: 1rem;
	border-bottom: 1px solid $rm-light-grey;
	.employee {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.employee-details {
			display: flex;
			align-items: center;
			.name {
				margin-left: 1rem;
				max-width: 26ch;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.add-employee {
			display: flex;
			align-items: center;
			padding-left: 10px;
		}
	}
}
.modal-messages {
	background-color: white;
	min-height: 5em;
	padding-top: 2em;
}
.modal-body {
	padding: 2em 1.5em 1em 0.5em !important;

	@include respond(large) {
		padding: 2em 1.5em 1em 1.5em !important;
	}
}
.btn-icon {
	font-size: 1.25em;
	color: $rm-grey;
}
</style>
