<template>
	<Alert :message="error" v-if="error" />

	<form class="rm-form p-1rem" @submit.prevent="handleSubmit">
		<div class="form-input">
			<label>Location Name:</label>
			<input type="text" v-model="locationName" />
		</div>
		<div class="form-input">
			<label>Address:</label>
			<input type="text" v-model="address" />
		</div>
		<div class="form-input">
			<label>State:</label>
			<input type="text" v-model="state" />
		</div>
		<div class="form-input">
			<label>Country:</label>
			<select v-model="country">
				<SelectCountry />
			</select>
		</div>
		<div class="form-input">
			<label>Zip:</label>
			<input type="text" v-model="zip" />
		</div>
		<div class="form-messages" v-if="success">
			<p id="success">{{ success }}</p>
		</div>
		<div class="form-submit">
			<page-loading v-if="updating" />
			<button class="btn btn-primary" v-else>Update profile</button>
		</div>
	</form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { projectFirestore } from "../../firebase/config";
import { onMounted } from "@vue/runtime-core";
import getDocumentOnce from "../../composables/getDocumentOnce";
import useDocument from "../../composables/useDocument";
import Alert from "../Alert";
import SelectCountry from "../SelectCountry";
import PageLoading from "../../components/PageLoading.vue";

export default {
	name: "UpdateLocationForm",
	components: { Alert, SelectCountry, PageLoading },
	props: ["locationId", "companyId"],
	setup(props) {
		const locationId = props.locationId;
		const companyId = props.companyId;
		const company = ref();

		// Form refs
		const locationName = ref("");
		const address = ref("");
		const state = ref("");
		const country = ref("");
		const zip = ref("");

		const error = ref(false);
		const updating = ref(false);
		const success = ref(null);

		// Data
		let db = projectFirestore;
		let companyRef = db.collection("companies").doc(companyId);

		let locationRef = companyRef.collection("locations").doc(locationId);

		// Get location once to pre-fill form
		onMounted(async () => {
			const { document: location } = await getDocumentOnce(locationRef);

			const { document } = await getDocumentOnce(companyRef);
			company.value = document.value;

			if (location.value) {
				locationName.value = location.value.locationName;
				address.value = location.value.address;
				state.value = location.value.state;
				country.value = location.value.country;
				zip.value = location.value.zip;
			}
		});

		const handleSubmit = async () => {
			updating.value = true;

			let update = {
				locationName: locationName.value.trim(),
				address: address.value,
				state: state.value,
				country: country.value,
				zip: zip.value,
			};

			const { updateDoc: updateLocation, error: updateCompanyError } =
				useDocument(locationRef);

			await updateLocation(update);

			if (updateCompanyError.value) {
				error.value = updateCompanyError.value;
			}

			// Update the company Document locations array
			// Assuming the array of locations is in a field named 'locationsArray'
			const locations = company.value.locations;

			// Find the index of the object to update
			const indexToUpdate = locations.findIndex(
				(location) => location.locationId === locationId
			);

			// Update the object at the found index
			if (indexToUpdate !== -1) {
				locations[indexToUpdate] = {
					locationName: locationName.value.trim(),
					locationId: locationId,
				};
			}

			const {
				updateDoc: updateCompanyDoc,
				error: updateCompanyDocError,
			} = useDocument(companyRef);

			if (updateCompanyDocError.value) {
				error.value = updateCompanyDocError.value;
			}

			await updateCompanyDoc({ locations: locations });

			if (!error.value) {
				success.value = "Location Updated";
				setTimeout(() => {
					success.value = null;
					updating.value = false;
				}, 3000);
			} else {
				updating.value = false;
			}
		};

		return {
			locationName,
			address,
			state,
			country,
			zip,
			handleSubmit,
			updating,
			error,
			success,
		};
	},
};
</script>

<style lang="scss" scoped>
#success {
	color: $rm-success;
}
</style>
