<template>
    <div class="right-nav">
        <div class="page-title">
            <h2>Locations</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: "LocationsDashNav",
    props: ["access"],
};
</script>


<style lang="scss" scoped>
.add-icon-link {
    display: inline-flex !important;
    align-items: center !important;
    width: auto !important;
}
</style>