<template>
	<div class="modal">
		<div class="modal-container">
			<div class="modal-content tile">
				<div class="modal-header">
					<div class="modal-title">
						<h3>Add employees</h3>
					</div>

					<router-link class="close" :to="{ name: 'LocationDetail' }">
						<fa icon="times" />
					</router-link>
				</div>
				<div class="modal-body">
					<div class="modal-messages" v-if="isLoading">
						<div class="error-text" v-if="error">{{ error }}</div>
						<page-loading />
					</div>
					<div
						class="employee-list"
						v-for="doc in filteredDocuments"
						:key="doc.id"
						v-else
					>
						<div class="employee">
							<div class="employee-details">
								<div class="circle-avatar">
									<img
										class="img-responsive"
										:src="doc.profile.profileImage"
									/>
								</div>
								<div class="name">
									{{ doc.profile.fullName }}
								</div>
							</div>
							<div class="add-employee">
								<div
									class="btn-icon"
									@click="addLocation(doc.id)"
								>
									<fa icon="user-plus" />
								</div>
								<div
									class="btn-icon"
									@click="removeLocation(doc.id)"
								>
									<fa icon="user-slash" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import {
	projectFirestore,
	addArrayItem,
	removeArrayItem,
} from "../../firebase/config";
import useCookies from "../../composables/useCookies";
import getCollection from "../../composables/getCollection";
import { useRouter, useRoute } from "vue-router";
import { computed } from "@vue/runtime-core";
import PageLoading from "../../components/PageLoading.vue";

export default {
	components: { PageLoading },
	name: "AddLocationEmployee",
	setup() {
		const route = useRoute();
		const companyId = route.query.companyId;
		let locationId = route.query.locationId;

		const error = ref("");
		const router = useRouter();
		const isLoading = ref(false);

		// Data
		let db = projectFirestore;

		let companyRef = db.collection("companies").doc(companyId);

		let employeesRef = companyRef.collection("employees");
		let locationsRef = companyRef.collection("locations");

		// Get employees
		const { documents } = getCollection(employeesRef);

		// Remove the manager from employee documents
		const filter = ref(null);

		const filteredDocuments = computed(() => {
			filter.value = documents.value;

			if (documents.value) {
				filter.value = filter.value.filter((e) => {
					return !e.manages.includes(locationId);
				});
			}

			return filter.value;
		});

		// Employee and location batch write
		let batch = db.batch();

		const addLocation = async (id) => {
			isLoading.value = true;

			// Add location to the employee
			let updateEmployeeRef = employeesRef.doc(id);
			batch.update(updateEmployeeRef, {
				locations: addArrayItem(locationId),
			});

			// Add employee to the location
			let updateLocationRef = locationsRef.doc(locationId);
			batch.update(updateLocationRef, { users: addArrayItem(id) });

			// Commit the batch
			batch
				.commit()
				.then(() => {
					isLoading.value = false;
					router.push({
						name: "LocationDetail",
						params: { id: locationId },
					});
				})
				.catch((err) => {
					console.log(err.message);
					error.value = "Could not add user";
					setTimeout(() => {
						isLoading.value = false;
						router.push({
							name: "LocationDetail",
							params: { id: locationId },
						});
					}, 2000);
				});
		};

		const removeLocation = async (id) => {
			isLoading.value = true;

			// Add location to the employee
			let updateEmployeeRef = employeesRef.doc(id);
			batch.update(updateEmployeeRef, {
				locations: removeArrayItem(locationId),
			});

			// Add employee to the location
			let updateLocationRef = locationsRef.doc(locationId);
			batch.update(updateLocationRef, { users: removeArrayItem(id) });

			// Commit the batch
			batch
				.commit()
				.then(() => {
					isLoading.value = false;
					router.push({
						name: "LocationDetail",
						params: { id: locationId },
					});
				})
				.catch((err) => {
					console.log(err.message);
					error.value = "Could not add user";
					setTimeout(() => {
						isLoading.value = false;
						router.push({
							name: "LocationDetail",
							params: { id: locationId },
						});
					}, 2000);
				});
		};

		return {
			documents,
			filteredDocuments,
			addLocation,
			removeLocation,
			error,
			isLoading,
		};
	},
};
</script>

<style lang="scss" scoped>
.employee-list {
	background-color: white;
	padding: 1rem;
	// padding: 1rem 1rem 1rem 0;
	border-bottom: 1px solid $rm-light-grey;

	.employee {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.employee-details {
			display: flex;
			align-items: center;
			.name {
				margin-left: 1rem;
				max-width: 26ch;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.add-employee {
			text-align: right;
		}
	}
}

.modal-messages {
	background-color: white;
	min-height: 5em;
	padding-top: 2em;
}
.modal-body {
	padding: 0.5em !important;
	@include respond(small) {
		padding: 2em 1.5em 1em 1.5em !important;
	}
}
.btn-icon {
	font-size: 1.25em;
	color: $rm-grey;
	padding-left: 1rem;
}
</style>
