<template>
    <Alert v-if="error" :message="error" />

    <!-- Navigation -->
    <div class="right-sub-nav fixed">
        <div class="nav-row">
            <div class="sub-nav-left">
                <div class="search">
                    <fa class="search-icon" icon="search" />
                    <input type="search" placeholder="Search Locations..." v-model="search" />
                </div>
            </div>
            <div class="sub-nav-right">
                <div class="page-links-alt" v-if="access">
                    <span>
                        <router-link class="add-icon-link" :to="{ name: 'AddNewLocation' }">
                            <p>Add New Location</p>
                            <fa class="add-icon" icon="plus-circle" />
                        </router-link>
                    </span>
                </div>
                <!-- <div
                    v-if="role == 'Admin'"
                    :class="{'muted': isActive, 'inActive': !isActive}"
                    @click="showInactive"
                >Inactive Employees</div>-->
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="right-content">
        <LocationsDashNav :access="access" />
        <div>
            <!-- Loading shimmer -->
            <div class="list-container" v-if="!locations">
                <div class="table-row-container">
                    <div class="table-row-shimmer shine"></div>
                    <div class="table-row-shimmer shine"></div>
                    <div class="table-row-shimmer shine"></div>
                </div>
            </div>

            <!-- documents list -->
            <div v-else class="list-container">
                <!-- empty list -->
                <div class="no-list vertical-center" v-if="locations && locations.length == 0">
                    <div class="icon">
                        <fa icon="building" />
                    </div>
                    <p>No locations found</p>
                </div>

                <div class="table-row-container">
                    <div v-for="location in locations" :key="location.id">
                        <router-link
                            :to="{ name: 'LocationDetail', params: { id: location.id }}"
                            class="row-link"
                            v-if="role == 'Admin' || location.users.includes(user.uid)"
                        >
                            <!-- document row -->
                            <div class="table-row">
                                <div class="row-left">
                                    <div class="title no-img-title">{{location.locationName}}</div>
                                    <div
                                        class="description"
                                    >{{location.state}}, {{location.country}}</div>
                                </div>
                                <div class="row-right">
                                    <div class="help-text">
                                        <p
                                            v-if="location.createdAt"
                                        >Created {{moment(location.createdAt.toDate()).format("MMMM Do YYYY")}}</p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <transition name="fade" mode="out-in">
        <router-view />
    </transition>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import LocationsDashNav from "@/components/locations/LocationsDashNav";
import getCollection from "@/composables/getCollection";
import { computed, ref, watchEffect } from "@vue/runtime-core";
import Alert from "@/components/Alert.vue";
import { projectFirestore } from "../../firebase/config";
import account from "../../composables/account";
import { useMeta } from "vue-meta";
import moment from "moment";
import ListLoading from "../../components/ListLoading.vue";

export default {
    name: "LocationsDash",
    components: { Sidebar, LocationsDashNav, Alert, ListLoading },
    setup() {
        useMeta({ title: "Locations" });
        const { user, companyId, userDoc, role } = account();

        const collectionRef = projectFirestore
            .collection("companies")
            .doc(companyId)
            .collection("locations");

        const { error, documents } = getCollection(collectionRef);

        const access = computed(() => {
            if (userDoc.value) {
                return userDoc.value.role == "Admin";
            }
        });

        const filteredlocations = ref([]);
        const search = ref("");

        const locations = computed(() => {
            if (documents.value != null && userDoc.value && role.value) {
                var data = [];

                filteredlocations.value = [];

                documents.value.forEach((el) => {
                    // if (el.isActive == isActive.value) {
                    //     filteredlocations.value.push(el);
                    // }

                    filteredlocations.value.push(el);
                });

                // If admin show all locations
                if (role.value == "Admin") {
                    data = filteredlocations.value;
                } else {
                    // Only show locations shared with user or the user's location
                    filteredlocations.value.forEach((e) => {
                        if (e.users.includes(user.value.uid)) {
                            data.push(e);
                        }
                    });
                }
            }

            // Then by search
            if (data && data.length) {
                data = data.filter((item) => {
                    return (
                        item.locationName
                            .toLowerCase()
                            .indexOf(search.value.toLowerCase()) != -1
                    );
                });
            }
            return data;
        });

        watchEffect(() => {
            if (error.value) {
                error.value = "Could not fetch locations";
            }
        });

        return {
            error,
            locations,
            access,
            moment,
            user,
            role,
            search,
        };
    },
};
</script>

<style lang="scss" scoped>
.search {
    input {
     //   width: 350px;
         width: 175px;

        @include respond(large) {
            width: 250px;
        }
        @include respond(larger) {
            width: 350px;
        }
        &::placeholder {
            font-size: 1em;
        }
    }
}
.table-row .row-left .title{
    font-size: 12px;
    width: 100px;
    @include respond(small){
        font-size: 14px;
        width: 230px;
    }
    @include respond(medium){
        width: 250px;
    }
    @include respond(large){
        font-size: 1em;
        width: 300px;
    }
}
</style>