<template>
    <div class="modal">
        <div class="modal-container">
            <div class="modal-content tile">
                <div class="modal-header">
                    <div class="modal-title">
                        <h3>New Location</h3>
                    </div>

                    <router-link class="close" :to="{ name: 'Locations' }">
                        <fa icon="times" />
                    </router-link>
                </div>
                <form id="modal-form" class="rm-form" @submit.prevent="handleSubmit">
                    <div class="spacer-20"></div>
                    <div class="form-input">
                        <label>Location Name:</label>
                        <input type="text" required v-model="locationName" />
                    </div>
                    <div class="form-input">
                        <label>Address:</label>
                        <input type="text" required v-model="address" />
                    </div>
                    <div class="form-input">
                        <label>State / County:</label>
                        <input type="text" required v-model="state" />
                    </div>
                    <div class="form-input">
                        <label>Country:</label>
                        <select required v-model="country">
                            <SelectCountry />
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Zip / Post Code:</label>
                        <input type="text" required v-model="zip" />
                    </div>
                    <div class="form-messages" v-if="success || error || !access">
                        <div
                            class="error-text"
                            v-if="!access"
                        >You must have an admin account to add new locations</div>
                        <div>{{success}}</div>
                        <div class="error-text">{{error}}</div>
                    </div>
                    <div class="form-submit">
                        <page-loading v-if="isLoading" />
                        <button
                            class="btn btn-primary"
                            v-if="!hideButton"
                            :disabled="!access"
                        >Create</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import {
    addArrayItem,
    projectFirestore,
    timestamp,
} from "../../firebase/config";
import { computed, onMounted } from "@vue/runtime-core";
import useCollection from "../../composables/useCollection";
import SelectCountry from "../../components/SelectCountry";
import getDocumentOnce from "../../composables/getDocumentOnce";
import account from "../../composables/account";
import PageLoading from "../../components/PageLoading.vue";

export default {
    name: "AddNewLocation",
    components: { SelectCountry, PageLoading },
    setup() {
        const { companyId, userDoc, user } = account();
        const router = useRouter();

        // Form refs
        const locationName = ref("");
        const address = ref("");
        const state = ref("");
        const country = ref("");
        const zip = ref("");
        const success = ref(null);

        const error = ref(null);
        const isLoading = ref(false);

        // Data
        let db = projectFirestore;

        let collectionRef = db.collection("companies").doc(companyId);

        let locations = collectionRef.collection("locations");

        let employeesRef = collectionRef.collection("employees");

        let batch = db.batch();

        let ownerId;

        onMounted(async () => {
            const { document } = await getDocumentOnce(collectionRef);
            ownerId = document.value.ownerUid;
        });

        const access = computed(() => {
            if (userDoc.value) {
                return userDoc.value.role == "Admin";
            }
        });

        const hideButton = computed(() => {
            return isLoading.value || success.value;
        });

        const handleSubmit = async () => {
            error.value = null;
            success.value = null;
            isLoading.value = true;

            const location = {
                locationName: locationName.value.trim(),
                address: address.value,
                state: state.value,
                country: country.value,
                zip: zip.value,
                createdAt: timestamp(),
                users: [ownerId, user.value.uid],
                manager: user.value.uid,
            };

            // Add location
            const {
                error: addDocErr,
                addDoc,
                documentId,
            } = useCollection(locations);

            await addDoc(location);

            if (addDocErr.value) {
                error.value = "Could not add location";
            }

            let currentUser = employeesRef.doc(user.value.uid);

            batch.update(currentUser, {
                locations: addArrayItem(documentId.value),
                manages: addArrayItem(documentId.value),
            });

            if (ownerId != user.value.uid) {
                let owner = employeesRef.doc(ownerId);
                batch.update(owner, {
                    locations: addArrayItem(documentId.value),
                });
            }

            let locationsUpdate = {
                locationId: documentId.value,
                locationName: locationName.value.trim(),
            };

            batch.update(collectionRef, {
                locations: addArrayItem(locationsUpdate),
            });

            batch
                .commit()
                .then(() => {
                    success.value = "Location created!";
                    setTimeout(() => {
                        isLoading.value = false;
                        router.push({ name: "Locations" });
                    }, 2000);
                })

                .catch((err) => {
                    isLoading.value = false;
                    console.log(err.message);
                    error.value = "Could not update users";
                });
        };

        return {
            error,
            handleSubmit,
            success,
            locationName,
            address,
            state,
            country,
            zip,
            isLoading,
            hideButton,
            access,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>