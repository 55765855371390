<template>
     <Sidebar />
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";

export default {
     name: "Locations",
     components: { Sidebar },
};
</script>

<style>
</style>