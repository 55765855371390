<template>
	<Alert :message="error" v-if="error" />

	<!-- Loading -->
	<!-- <div class="right-content" v-if="!location">
        <div class="right-nav">
            <div class="page-title">
                <h2>
                    <span class="back" @click="handleClick">Locations</span>
                    /
                </h2>
            </div>
        </div>
        <div class="page-content">
            <page-loading />
        </div>
    </div>-->

	<!-- Content -->
	<div class="right-content">
		<div class="right-nav">
			<div class="page-title">
				<h2 v-if="location">
					<span class="back" @click="handleClick">Locations</span>
					/ {{ location.locationName }}
				</h2>
			</div>
		</div>

		<!-- Locations content -->
		<div class="page-content">
			<div class="page-container">
				<div class="split">
					<!-- Col 1 -->
					<!-- Loading shimmer -->
					<div class="row" v-if="!location">
						<div class="left-title flex">
							<div class="lines shine"></div>
						</div>

						<div>
							<div class="table-row-shimmer shine"></div>
						</div>
					</div>

					<!-- Detail -->
					<div v-else class="row detail-left">
						<div class="row">
							<div class="left-title">
								<h3>{{ location.locationName }} Address:</h3>
							</div>
						</div>
						<div class="row">
							<div class="tile">
								<div
									class="tile-btn-container"
									v-if="access && !form"
									@click="showForm"
								>
									<div class="tile-btn">
										<div class="btn-icon muted">
											<fa icon="pen" />
										</div>
									</div>
								</div>
								<div
									class="tile-btn-container"
									v-if="form"
									@click="showForm"
								>
									<div class="tile-btn">
										<div class="btn-icon muted">
											<div class="text">close</div>
											<fa icon="times" />
										</div>
									</div>
								</div>
								<div v-if="!form">
									<div class="detail">
										<div class="detail-title">
											<b>{{ location.address }}</b>
										</div>
									</div>
									<div class="detail">
										<div class="detail-title">
											<b>{{ location.state }}</b>
										</div>
									</div>
									<div class="detail">
										<div class="detail-title">
											<b>{{ location.country }}</b>
										</div>
									</div>
									<div class="detail">
										<div class="detail-title">
											<b>{{ location.zip }}</b>
										</div>
									</div>
								</div>
								<div v-else>
									<UpdateLocationForm
										:locationId="location.id"
										:companyId="companyId"
									/>
								</div>
							</div>
							<Documents
								v-if="!form"
								:companyId="companyId"
								collection="locations"
								:assetId="location.id"
								:access="access"
								:showAddIcon="access"
							/>
						</div>
					</div>

					<!-- Col 2 -->
					<div class="row" v-if="location">
						<LocationMembers
							:locationId="location.id"
							:companyId="companyId"
							:userDoc="userDoc"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="in-out">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
import Alert from "../../components/Alert";
import LocationsDashNav from "@/components/locations/LocationsDashNav";
import LocationMembers from "../../components/locations/LocationMembers";
import UpdateLocationForm from "../../components/locations/UpdateLocationForm";
import Documents from "../../components/Documents.vue";

import getDocument from "../../composables/getDocument";

import { useRouter } from "vue-router";
import { projectFirestore } from "../../firebase/config";
import { computed, ref, watchEffect } from "@vue/runtime-core";
import account from "../../composables/account";
import { useMeta } from "vue-meta";

export default {
	name: "LocationDetail",
	components: {
		LocationsDashNav,
		Alert,
		LocationMembers,
		UpdateLocationForm,
		Documents,
	},
	props: ["id"],
	setup(props) {
		useMeta({ title: "Location Details" });
		const { error, userDoc, companyId } = account();
		const locationId = props.id;
		const router = useRouter();
		const form = ref(false);

		let collectionRef = projectFirestore
			.collection("companies")
			.doc(companyId);

		// Collection
		let locationRef = collectionRef.collection("locations").doc(locationId);
		const { error: getDocErr, document: location } =
			getDocument(locationRef);

		// Back to locations link
		const handleClick = () => {
			router.push({ name: "Locations" });
		};

		// Access
		const access = computed(() => {
			if (userDoc.value) {
				return (
					userDoc.value.role == "Admin" ||
					userDoc.value.manages.includes(locationId)
				);
			}
		});

		// Show/hide update form
		const showForm = () => {
			if (form.value) {
				form.value = false;
			} else {
				form.value = true;
			}
		};

		// Handle errors
		watchEffect(() => {
			if (getDocErr.value) {
				error.value = "Could not fetch location";
			}
		});

		return {
			location,
			error,
			handleClick,
			access,
			userDoc,
			companyId,
			form,
			showForm,
		};
	},
};
</script>

<style lang="scss" scoped>
.location-docs {
	// width: 65%;
	width: 100%;
}
.table-row-shimmer {
	height: 100px;
	border-radius: 0.5em;
	width: 100%;
	max-width: 50ch;
}
.lines {
	height: 1.2em;
	width: 100px;
	border-radius: 6px;
	margin: 1em;
}
.detail-left {
	min-width: 100% !important;

	@include respond(small) {
		min-width: 30% !important;
	}
	@include respond(larger) {
		min-width: 45% !important;
	}
}
.page-content {
	left: 0 !important;
	top: 140px !important;

	@include respond(medium) {
		left: 210px !important;
		top: 70px !important;
	}
}

.split {
	flex-direction: column !important;

	@include respond(small) {
		flex-direction: row !important;
	}
}
//.right-content h2{
//size: 16px !important;

// @include respond(small){
//font-size: 18px !important;
// }
//}
.left-title {
	font-size: 13px;

	@include respond(large) {
		font-size: 15px;
	}
}
</style>
