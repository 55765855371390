<template>
	<Alert :message="error" v-if="error" />

	<!-- Manager -->
	<div class="row">
		<div v-for="manager in filterManager" :key="manager.id">
			<!-- Title -->
			<div class="collection-title">
				<h3>Location Manager:</h3>
				<router-link
					v-if="adminAccess"
					class="add-icon-link"
					:to="{
						name: 'AddLocationManager',
						query: {
							locationId: locationId,
							managerId: manager.id,
							companyId: companyId,
						},
					}"
				>
					<p>Change Manager</p>
					<fa class="add-icon" icon="plus-circle" />
				</router-link>
			</div>

			<!-- Manager -->
			<router-link
				:to="{ name: 'Profile', params: { id: manager.id } }"
				class="row-link"
			>
				<div class="table-row">
					<div class="row-left">
						<div class="circle-avatar">
							<img :src="manager.profile.profileImage" />
						</div>
						<div class="title">{{ manager.profile.fullName }}</div>
						<div class="description">
							{{ manager.profile.jobTitle }}
						</div>
					</div>
					<div class="row-right">
						<p class="help-text">{{ manager.role }}</p>
					</div>
				</div>
			</router-link>
		</div>
	</div>

	<!-- Employees -->
	<div class="spacer-20"></div>
	<div class="row">
		<div class="collection-title">
			<h3>The Team:</h3>
			<router-link
				v-if="managerAccess"
				class="add-icon-link"
				:to="{
					name: 'AddLocationEmployee',
					query: { locationId: locationId, companyId: companyId },
				}"
			>
				<p>Add &amp; Remove Team Members</p>
				<fa class="add-icon" icon="plus-circle" />
			</router-link>
		</div>

		<!-- Employees list -->
		<div class="table-row-container">
			<div
				v-for="employee in filterEmployees"
				:key="employee.id"
				v-if="filterEmployees && filterEmployees.length > 0"
			>
				<router-link
					:to="{ name: 'Profile', params: { id: employee.id } }"
					class="row-link"
					v-if="employee.id != ''"
				>
					<!-- document row -->
					<div class="table-row">
						<div class="row-left">
							<div class="circle-avatar">
								<img :src="employee.profile.profileImage" />
							</div>
							<div class="title">
								{{ employee.profile.fullName }}
							</div>
							<div class="description">
								{{ employee.profile.jobTitle }}
							</div>
						</div>
						<div class="row-right">
							<p class="help-text">{{ employee.role }}</p>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import getCollection from "../../composables/getCollection";
import { projectFirestore } from "../../firebase/config";

import Alert from "../../components/Alert";

export default {
	name: "LocationMembers",
	components: { Alert },
	props: ["locationId", "companyId", "userDoc"],
	setup(props) {
		const companyId = props.companyId;
		const locationId = props.locationId;
		const userDoc = ref(props.userDoc);

		const filteredEmployees = ref(null);
		const filteredManager = ref(null);

		// Data
		let collectionRef = projectFirestore
			.collection("companies")
			.doc(companyId);

		let employeesRef = collectionRef
			.collection("employees")
			.where("isActive", "==", true)
			.where("locations", "array-contains", locationId);
		const { error, documents: employees } = getCollection(employeesRef);

		const filterEmployees = computed(() => {
			let localFilteredEmployees = employees.value;

			if (employees.value) {
				if (employees.value.length) {
					localFilteredEmployees = localFilteredEmployees.filter(
						(employee) => {
							return !employee.manages.includes(locationId);
						}
					);
				}
			}

			return localFilteredEmployees;
		});

		const filterManager = computed(() => {
			let localFilteredManager = employees.value;

			if (employees.value) {
				localFilteredManager = localFilteredManager.filter(
					(manager) => {
						return manager.manages.includes(locationId);
					}
				);
			}

			return localFilteredManager;
		});

		const adminAccess = computed(() => {
			if (userDoc.value) {
				return userDoc.value.role == "Admin";
			}
		});

		const managerAccess = computed(() => {
			if (userDoc.value) {
				return (
					userDoc.value.manages.includes(locationId) ||
					userDoc.value.role == "Admin"
				);
			}
		});

		return {
			employees,
			adminAccess,
			managerAccess,
			error,
			locationId,
			companyId,
			filterEmployees,
			filterManager,
		};
	},
};
</script>

<style lang="scss" scoped>
.table-row {
	@include respond(small) {
		width: 70% !important;
	}
	@include respond(large) {
		width: 100% !important;
	}
	.row-left {
		.title {
			width: 15ch;
		}
	}
}
.collection-title {
	font-size: 13px;
	@include respond(small) {
		width: 72% !important;
	}
	@include respond(large) {
		width: 100% !important;
		font-size: 15px;
	}
}
</style>
